<template>
  <div class="">
    <appintegraplugin pluginName="recurso-form/recurso" :key="refresh"/>
  </div>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import appintegraplugin from "@/components/plugins2/app-integra-plugin.vue";

export default {
  name: "router",
  components: {
    appintegraplugin
  },
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      pluginName: "",
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    self.build();
  },
  methods:{

    build() {
      var self = this;
    }
  }
}
</script>

<style scoped>
</style>
